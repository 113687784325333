.pv-center {
    margin-left: 20px;
    margin-right: 14px;
}
.treegriddiv table th
{
    background: -webkit-gradient(linear, left bottom, left top,
     color-stop(0, #eeeeee), color-stop(1, #ffffff)) !important;
     height: 52px;
}
.settooltip .tooltip .tooltip-inner {
    max-width: 600px !important;
    white-space: normal;
    text-align: left;
  }
  
  .grid .tooltip .tooltip-inner {
    min-width: 0px !important;
    width: auto !important;
    white-space: normal;
    max-width: max-content;
    text-align: left;
  }
  .autocomplete-container .suggestions-container ul li a
  {
      padding: 7px 15px !important;
  }
  .popuptooltip .tooltip .tooltip-inner{
    min-width: 0px !important;
    width: auto !important;
    white-space: normal;
    max-width: 400px;
    text-align: left;
  }
  .questionnaire
 
.blackColor{color: black;}
.orangeColor{color: orange;}
.enableColor {color:green}
.cancelColor{color:red}
.pendingColor{color:#181d1f}
.disableColor{color:grey}
.parialApproved {color: blue; }
.completedColor{color: royalblue}
.whitecolor{color: white; font-size: 18px; font-weight: bold;}
.mousehand{
    cursor: pointer;
}
.anchor{
    color: blue;
    cursor: pointer;
    text-decoration:underline;
}
button.close {
    border: none !important; opacity: 1 !important;
}
button.close:focus {
    outline: none !important;
}
.tooltip_executionstatus {
        display: inline-block;
}

.gridTable td {
    padding: 0 10px 2px 0;
   }

.valignmiddle {
    vertical-align: middle !important;
}
.marginbotton10
{
    margin-bottom: 10px;
}
.padding5{
    padding: 5px;
}
.pad-left8
{
    padding-left: 8px;
}
.width200{
    width: 200px;
}
.uploadmaindiv
{
   padding: 16px;
}

.pad-top-60
{
    padding-top: 60px;
}
.margin-left5
{
    margin-left: 5px;
}

.gridtable tr{
    height: 60px;
}
.gridtable td {
    padding: 0 10px 2px 0;
    vertical-align: bottom;
}
.gridtable label {
    font-size: 14px;
    padding: 10px 10px;
}
.valignmiddle {
    vertical-align: middle !important;
}
.treegriddiv table th {
    vertical-align: top !important;
}
label {
    color: #666;
    font-weight: normal !important;
}
.gridTable label {
    font-size: 14px;
    padding: 10px 10px;
}
.font14{
    font-size: 14px;
}
.width400
{
    width: 400px;
}
.width350
{
    width: 350px;
}
.autocomplete-container .input-container input {
    padding: 5px !important;
    font-size: inherit !important;
}
.submitdiv{
    text-align: right;
    height: 60px;
}
.mheight80{
    max-height: 80px;
    overflow: auto;
}
.mwidth110{
    min-width: 110px;
}

.btn-outline-success{
        color: black !important;
        border-color: rgb(169, 169, 169) !important;
}
.autocomplete-container .suggestions-container .complete-selected, .autocomplete-container .suggestions-container ul li:hover {
    background-color: #007bff !important;
}
.dropdown-item:hover{ background-color: #007bff !important;color: white !important;}
.dropdown-toggle::after {
    float: right !important;margin-top: 7px !important;
}
.dropdown-toggle:focus {
    outline: -webkit-focus-ring-color auto 1px !important;box-shadow: none !important;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle
{
    background-color: white !important;
}
/* .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: black !important;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus 
{
    box-shadow: black !important; 
} */
.btn-outline-success:hover {
    color: black !important;
    background-color:white !important;
    border-color: black !important;
}

/* 
   ============================== */

.mright10{
    margin-right: 15px;
}
.modal-internal-header {
    background: green; vertical-align: middle;padding-left: initial;
  }
  h4{color: #fff;}
.nopadf16{
    padding-left: 0px !important;font-size: 16px;
}
.plefttop{
    padding-left: 10px;
    padding-top: 7px;
}
.mat-horizontal-stepper-header{
    pointer-events: none !important;
}
button {height: 33px; font-size: 12px !important;}
.pleft12{
    padding-left: 12px;
}
#loading {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: block;
    opacity: 0.7;
    background-color: rgb(99, 91, 91);
    z-index: 99;
    text-align: center;
  }
  
  #loading-image {
    font-size: 70px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
  }
  .home{
    float: left;
    padding-top: 12px;
    font-size: 22px !important;
    color: white;
  }
  .fright
  {
      float: right;
  }
  .padleft5 {
      padding-left: 5px;
  }
  .grid-cell-centered { justify-content:left;}
.gridTable-internal {padding: 16px;}
.pad12 {padding: 12px;}
.cardheading {
    background-color: green !important;
    color: white;
}
.disinline{
    display: inline !important;
}
.pad0{
    padding: 0px !important;
}
.fa-disabled {
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none !important
  }
  .pad-top15 {
      padding-top: 15px;
  }
  .txtcenter{
    text-align: center;
  }
  /* ============================== widget css ===================================== */

  .topcorner{
    box-shadow: 5px 5px 5px 8px #888888;
    position:absolute;
    border-radius: 12px;
    background:white;
    top:15%;
    right:35px;
    height: auto;
    width: 220px;
    z-index: 1000;
   }
  
   .topcorner1{
    box-shadow: 5px 5px 5px 8px #888888;
    position:absolute;
    border-radius: 12px;
    background:white;
    top:200px;
    right:35px;
    height: auto;
    width: 500px;
    z-index: 1000;
   }
   
.feedback {
  background-color : #31B0D5;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border-color: #46b8da;
}
#mybutton {
    position: fixed;
    top: 25px;
    cursor: pointer;
    right: 16px;
    z-index: 10000;
  }
  .pointer{
    cursor: pointer;
  }
  .header-left{
    height:auto;
    width: 100%;
    padding-left: 10px;
    border-top-left-radius:12px;
    border-top-right-radius:12px;
    background-color: green;color: white;
    font-size: 14px;
    padding-bottom: 5px;
  }
  
  crossbutton-left{
    border: 1px solid green;
      transform: rotate(45deg);
      width: 21px;
      height: 21px;
      margin: -12px 0 0 -12px;
      float: right;
      border-radius: 50%;
      background: #FFF;
      box-sizing: border-box;
      position: absolute;
      cursor: pointer;
      z-index: 10;
  }
  .crossbutton {
    border: 1px solid green;
      transform: rotate(45deg);
      width: 21px;
      height: 21px;
      margin: -12px 0 0 -12px;
      float: left;
      border-radius: 50%;
      background: #FFF;
      box-sizing: border-box;
      position: absolute;
      cursor: pointer;
      z-index: 10;
  }
  .crossbutton:after {
    position: absolute;
    content: '';
    top: 9px;
    left: 5px;
    width: 9px;
    height: 1px;
    background: green;
  }
  .crossbutton:before {
    position: absolute;
    content: '';
    top: 5px;
    left: 9px;
    height: 9px;
    width: 1px;
    background: green;
  }
  .header-right {
    height:auto;
    background-color: green;color: white;
    border-top-right-radius: 12px;
    font-size: 14px;
    padding-bottom: 5px;
  }
  .pad-left {
   padding: 0px 0px 0px 5px !important;
  }

  /* ============================== widget css ===================================== */

.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done, .mat-step-header .mat-step-icon-state-edit{
    color:white !important; 
    background-color:rgb(112, 112, 110) !important;
   
} 
.mat-step-header .mat-step-icon-selected, .mat-step-header .mat-step-icon-state-done{
    color: white !important; 
    background-color: #673ab7 !important;
}
.background-green{
    background-color: green;
}
.width-fitContent{
    width: fit-content;
}